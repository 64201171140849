export const API_URL = process.env.API_URL;
export const APP_VERSION = process.env.APP_VERSION;
export const MEDIA_BASE_URL = process.env.MEDIA_BASE_URL;
export const DEFAULT_DATASET_ID = 'revops-suggestions';
export const APP_DEBUG = process.env.APP_DEBUG;

export const DEFAULT_LINK_REFERRER_POLICY = 'strict-origin-when-cross-origin, no-referrer-when-downgrade';
export const DEFAULT_LINK_INTERNAL_REL = '';
export const DEFAULT_LINK_EXTERNAL_REL = 'sponsored';
export const DEFAULT_LINK_INTERNAL_TARGET = '_self';
export const DEFAULT_LINK_EXTERNAL_TARGET = '_blank';
